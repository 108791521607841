<template>
  <div v-if="!isInitalizing">
    <b-row>
      <!-- selectie -->
      <b-col cols="12" :hidden="isFastCashier">
        <b-row
            v-if="currentItem.date"
            class="mb-2"
        >
          <b-col cols="12">
            <b-card :header="$t('alg.selectie')"
                    class="border-0 shadow-sm"
            >
              <b-row>
                <div
                    v-if="currentItem.date"
                    class="p-2"
                >
                  {{ $t('alg.datum') }}:
                  <b-button class="" v-on:click="removeSelection('date')">
                    {{ currentItem.date | moment('DD-MM-YYYY') }}
                    <b-badge variant="danger">X</b-badge>
                  </b-button>
                </div>
                <div
                    v-if="currentItem.data.a"
                    class="p-2"
                >
                  {{ $t('alg.aanbod') }}:
                  <b-button class="" v-on:click="removeSelection('assortment')">
                    {{ currentItem.data.a.weergavenaam }}
                    <b-badge variant="danger">X</b-badge>
                  </b-button>
                </div>
                <div
                    v-if="currentItem.data.s"
                    class="p-2"
                >
                  {{ $t('resdetails.blok') }}/{{ $t('resdetails.tijdsduur') }}:
                  <b-button class="" v-on:click="removeSelection('slot')">{{ currentItem.data.s.Blockname }}
                    <b-badge variant="danger">X</b-badge>
                  </b-button>
                </div>
                <div
                    v-if="currentItem.starttime"
                    class="p-2"
                >
                  {{ $t('alg.starttijd') }}:
                  <b-button class="" v-on:click="removeSelection('starttime')">
                    {{ currentItem.starttime.substring(0, 5) }}
                    <b-badge variant="danger">X</b-badge>
                  </b-button>
                </div>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <!-- aanbod selector -->
      <b-overlay variant="secondary" :show="isLoading" no-wrap>
        <template #overlay>
          <div class="d-flex flex-column my-auto">
            <div class="w-100 text-center">
              <b-spinner></b-spinner>
            </div>
            <div class="w-100 text-center">{{ $t('alg.gegevensophalen') }} ...</div>
          </div>
        </template>
      </b-overlay>

      <b-col cols="12">
        <!-- datum -->
        <b-row class="mb-2" v-if="!currentItem.date">
          <b-col cols="12">
            <b-card :header="$t('alg.datum')"
                    class="border-0 shadow-sm"
            >
              <b-calendar
                  v-model="currentItem.date"
                  class="mr-1 mb-2"
                  :placeholder="$t('reslijst.datumpickernieuw')"
                  block
                  hide-header
                  start-weekday="1"
                  :min="today"
              ></b-calendar>
            </b-card>
          </b-col>
        </b-row>

        <!-- assortiment -->
        <b-row class="mb-2" v-if="currentItem.date && (!currentItem.assortment || isFastCashier)">
          <b-col cols="12" class="">
            <b-card
                class="border-0 shadow-sm"
                no-body
            >
              <template v-slot:header>
                <div class="d-flex justify-content-between">
                  <div v-if="!isFastCashier">{{ $t('alg.activiteiten') }}</div>
                  <div v-if="isFastCashier">Items</div>
                  <div class="ml-3 header-normal">
                    <b-form-checkbox v-show="!isFastCashier" v-model="fetchAvailablity" size="sm" switch
                                     @change="toggleFetchAvailablity">
                      {{ $t("alg.voorraadinfo") }}
                    </b-form-checkbox>
                  </div>
                </div>
              </template>
              <b-tabs
                  justified
                  class="mt-2 mx-2"
                  align="center"
                  :active-nav-item-class="['bg-white', 'border-bottom-0']"
                  :nav-class="['bg-secondary' , 'border-bottom-0']"
                  v-model="currentTab"
              >
                <b-tab
                    v-for="(content, name) in assortimentTabs"
                    v-bind:key="name"
                >
                  <template v-slot:title>
                    <b-badge pill variant="info">{{ content.length }}</b-badge>
                    <br>
                    <span class="font-weight-bolder small">{{ name | capitalize }} </span>
                  </template>
                  <b-card-body>
                    <b-form-input
                        id="filter"
                        v-model="filterValue"
                        v-on:input="filtering"
                        type="search"
                        :placeholder="$t('alg.filter')"
                        class="m-1"
                    ></b-form-input>
                    <div class="tiles">
                      <div class="tile assortiment p-2 m-1"
                           v-for="(assortment, key) in displayAssortmentList"
                           v-bind:key="key"
                           v-on:click="setCurrentAssortiment(assortment.id_assortiment)"
                           :disabled=availability(assortment.id_assortiment).disabled
                           :class="availability(assortment.id_assortiment).disabled ? 'disabled' : ''"
                      >
                        <div class="w-100" v-if="assortment['FKid_soort'] !== '5'">
                          <b-progress
                              :max="100"
                              class="border border-info"
                          >
                            <b-progress-bar class="position-relative"
                                            :striped="availability(assortment.id_assortiment).striped"
                                            :value="availability(assortment.id_assortiment).perc"
                                            :class="availability(assortment.id_assortiment).statusClass">
                              <span v-if="availability(assortment.id_assortiment).desc !== ''"
                                    class="justify-content-center d-flex position-absolute w-100">{{
                                  availability(assortment.id_assortiment).desc
                                }}</span>
                            </b-progress-bar>
                          </b-progress>
                        </div>
                        <div class="w-100 text-center">
                          <span>{{ assortment.weergavenaam }}</span>
                        </div>
                        <div class="w-100 text-center" v-if="assortment.isAffiliate">
                              <span class="small font-italic">
                                {{ assortment.affiliate_provider.shopname }}
                              </span>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
        <template v-if="!isFastCashier">
        <!-- blok en starttijd -->
        <b-row class="mb-2" v-if="currentItem.assortment && !currentItem.starttime && slots.length > 0">
          <b-col cols="12">
            <b-card
                class="border-0 shadow-sm"
            >
              <template v-slot:header>
                <div class="d-flex justify-content-between">
                  <div>{{ $t('resdetails.blok') }} + {{ $t('resdetails.tijdsduur') }}</div>
                  <div class="ml-3 header-normal">
                    <b-form-checkbox v-model="hideDisabledSlots" switch
                                     size="sm"
                    >
                      {{ $t("alg.filter") | capitalize }} {{ $t("resdetails.blokuitgeschakeld") }}
                    </b-form-checkbox>
                  </div>
                </div>
              </template>
              <b-overlay variant="secondary" :show="isLoading" no-wrap></b-overlay>
              <div class="tiles">
                <div class="tile slots p-2 m-1"
                     v-for="slot in slots"
                     v-bind:key="slot.ID_Block"
                     v-on:click="setCurrentSlot(slot)"
                     :disabled="availabilitySlot(slot).disabled"
                     v-show="hideDisabledSlots ? !availabilitySlot(slot).disabled: true"
                     :class="availabilitySlot(slot).disabled ? 'disabled' : ''"
                >
                  <b-progress
                      :max="100"
                      class="border border-info"
                  >
                    <b-progress-bar class="position-relative"
                                    :striped="availabilitySlot(slot).striped"
                                    :value="availabilitySlot(slot).perc"
                                    :class="availabilitySlot(slot).statusClass">
                              <span v-if="availabilitySlot(slot).desc !== ''"
                                    class="justify-content-center d-flex position-absolute w-100"
                                    v-html="availabilitySlot(slot).desc"
                              >
                                </span>
                    </b-progress-bar>
                  </b-progress>
                  <div class="w-100 text-center">
                    <div>{{ slot.Blockname }}</div>
                    <small v-if="slot.BlockStart.substring(0, 5) !== '00:00'">{{ slot.BlockStart.substring(0, 5) }} -
                      {{ slot.BlockEnd.substring(0, 5) }}</small>
                  </div>
                </div>
              </div>

              <div
                  v-if="this.currentItem.data.s && this.currentItem.data.s.timeselection"
                  class="mt-2 px-1"
              >
                {{ $t('alg.selecteerstarttijd') }}:
                <b-form-select
                    v-model="currentItem.starttime"
                    v-if="this.currentItem.data.s.timeselection"
                    @change="setCurrentStarttime($event)"
                >
                  <b-form-select-option
                      v-for="option in this.currentItem.data.s.timeselection"
                      v-bind:key="option.BlockStart"
                      :value="option.BlockStart"
                  >{{ option.BlockStart.substring(0, 5) }} - {{ option.BlockEnd.substring(0, 5) }}
                  </b-form-select-option>
                </b-form-select>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <!-- tarief -->
        <b-row class="mb-2" v-if="currentItem.starttime !== ''">
          <b-col cols="12">
            <b-card header-tag="header"
                    class="border-0 shadow-sm"
            >
              <template #header>
                {{ $t('resdetails.aantal') }}
              </template>
              <b-row>
                <b-col cols="12" md="3" offset-md="7"
                       class="text-center mb-2"
                       v-if="this.currentItem.max"
                >
                  <span v-bind:class="(this.currentItem.max <= 0 ? 'text-danger' : 'text-success')">
                    {{ $t('alg.beschikbaar') }}:  {{
                      (this.currentItem.max > 99999 ? '&#8734;' : this.currentItem.max)
                    }}
                  </span>
                  <div class="text-secondary">{{ $t('alg.minimaleafname') }}: {{ this.currentItem.data.a.minpax }}</div>
                </b-col>
              </b-row>

              <template
                  v-if="currentItem.tarif"
              >
                <b-row
                    v-for="tarif in tarifs"
                    v-bind:key="tarif.id_tarif"
                    class="align-middle"
                >
                  <b-col cols="6" md="5">{{ tarif.description }}</b-col>
                  <b-col cols="6" md="2">
                    <span v-html="tarif.symbol"></span> {{ tarif.price | money(tarif.currency) }}
                  </b-col>
                  <b-col cols="6" md="3">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <button
                            class="btn btn-outline-primary"
                            type="button"
                            v-on:click="chgPax('sub', tarif.id_tarif)"
                        >-
                        </button>
                      </div>
                      <input type="text"
                             class="form-control text-center"
                             readonly="readonly"
                             v-model="currentItem.tarif[tarif.id_tarif].pax"
                      >
                      <div class="input-group-append">
                        <button
                            class="btn btn-outline-primary"
                            type="button"
                            v-on:click="chgPax('add', tarif.id_tarif)"
                        >+
                        </button>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="6" md="2"><span v-html="tarif.symbol"></span>
                    {{ (currentItem.tarif[tarif.id_tarif].pax * tarif.price) | money(tarif.currency) }}
                  </b-col>
                </b-row>
              </template>
              <b-row class="mb-2">
                <b-col cols="6" md="2" offset-md="8"
                       class="font-weight-bold text-muted text-right"
                >
                  {{ $t('alg.totaal') }}:
                </b-col>
                <b-col cols="6" md="2">
                  € {{ subtotal | money(1) }}
                </b-col>
              </b-row>
              <b-button v-if="currentItem.data && currentItem.data.a"
                        :disabled="currentItem.pax < currentItem.data.a.minpax"
                        v-on:click="addToCart()"
                        class="float-right"
              >
                {{ $t('alg.toevoegenwinkelwagen') }}
              </b-button>
            </b-card>
          </b-col>
        </b-row>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AssortimentService from '../services/assortiment.service';

export default {
  name: "AddReservationLines",
  props: ['propdate', 'fastcashier'],
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    return {
      today: today,
      total: 0,
      subtotal: 0,
      pax: 0,
      slots: [],
      tarifs: [],
      assortmentList: [],
      assortmentListAvailability: {},
      assortimentTabs: {},
      displayAssortmentList: [],
      orderlines: [],
      id_reservering: '',
      filterValue: '',
      currentItem: {
        data: {
          a: null,
          s: null,
          t: null,
        },
        assortment: null,
        date: null,
        max: null,
        pax: 0,
        starttime: "",
        slot: null,
        tarif: null,
      },
      currentTab: 0,
      fetchAvailablity: true,
      hideDisabledSlots: true,
      isLoading: false,
      isInitalizing: true
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/currentUser"]
    },
    currentShop() {
      return this.$store.getters["bus/currentShop"]
    },
    isFastCashier() {
      return this.$props.fastcashier
    }
  },
  methods: {
    scrollUp() {
      this.$scrollTo('#top', 300, {offset: -70})
    },
    getAssortiment() {
      return new Promise((resolve) => {
        this.assortmentList = this.$store.getters["misc/getAssortimentsData"][this.currentShop.hashcode].map((item) => {
          item.id_assortiment = parseInt(item.id_assortiment)
          return item
        });

        const activeIdList = this.assortmentList.map((a) => a.id_assortiment)
        let assortimentTabs = [];
        const at = this.$store.getters["bus/currentShop"].options['assortiment-tabs']
        for (const tab in at) {
          assortimentTabs[tab] = at[tab]
              .filter((i) => activeIdList.includes(i.assortimentId))
              .map((i) => {
                if (i && i.assortimentId) {
                  const as = this.assortmentList.filter((a) => a.id_assortiment === i.assortimentId)
                  if (as[0]) {
                    return as[0]
                  }
                }
              })
        }
        assortimentTabs[this.$t(`assortimentTypes.${1}`)] = this.filterOnType(1)
        assortimentTabs[this.$t(`assortimentTypes.${5}`)] = this.filterOnType(5)
        assortimentTabs[this.$t(`assortimentTypes.${'isAffiliate'}`)] = this.filterOnType('isAffiliate')

        let defTabs = [];
        if (this.isFastCashier) {
          for (const tab in assortimentTabs) {
            assortimentTabs[tab] = assortimentTabs[tab].filter((a) => a["FKid_soort"] === "5")
            if (assortimentTabs[tab].length > 0) {
              defTabs[tab] = assortimentTabs[tab]
            }
          }
        } else {
          defTabs = assortimentTabs
        }
        resolve(defTabs)
      })
    },
    async getBlokken(id_assortiment) {
      this.isLoading = true
      await AssortimentService.getSlots(id_assortiment, this.currentItem.date)
          .then(
              data => {
                if (data.StockStatus === "N" || data.StockStatus === "C") {
                  this.throwError(data)
                  return
                }
                this.slots = data.map((item) => {
                  item.ID_Block = parseInt(item.ID_Block)
                  return item
                });
              }
          )
          .catch(
              err => {
                this.resdetails = []
                this.toast('err', err)
              })
      this.isLoading = false
    },
    throwError(data) {
      this.toast('err', data.StockDetails)
    },
    async getTarif(id_slot) {
      return new Promise( (resolve) => {
        AssortimentService.getTarif(this.currentItem.assortment, id_slot)
            .then(
                data => {
                  this.tarifs = data;

                  const arr_tarif = {}
                  for (const dat in data) {
                    arr_tarif[data[dat].id_tarif] = {'pax': 0}
                  }
                  this.currentItem.data.t = data
                  this.currentItem.tarif = arr_tarif
                  resolve()
                }
            )
            .catch(
                err => {
                  this.resdetails = []
                  this.toast('err', err)
                  resolve()
                })
      })

    },
    async getStockDetails(starttime) {
      try {
        let availableStock = await AssortimentService.getStockDetails(this.currentItem.assortment, this.currentItem.slot, this.currentItem.date, starttime)
        this.currentItem.max = availableStock.Free < 0 ? this.currentItem.data.a.maxpax : availableStock.Free;
      } catch (err) {
        this.toast('err', err)
      }
    },
    setCurrentAssortiment(id_assortiment) {
      if (this.availability(id_assortiment).disabled) {
        return
      }
      this.currentItem.slot = null;
      this.currentItem.tarif = null;
      this.currentItem.pax = null;
      this.slots = [];
      this.tarifs = [];
      this.currentItem.assortment = id_assortiment;

      this.currentItem.data.a = this.displayAssortmentList.find(o => o.id_assortiment === id_assortiment);
      if (this.currentItem.data.a.FKid_soort === "5") {
        this.setCurrentSlot(0)
      } else {
        this.getBlokken(id_assortiment)
      }
    },
    async setCurrentSlot(slot) {
      if (this.availabilitySlot(slot).disabled) {
        return
      }
      this.currentItem.slot = slot.ID_Block;
      this.currentItem.tarif = null;
      this.currentItem.pax = null;

      this.currentItem.data.s = slot;

      if (parseInt(this.currentItem.data.a.starttijden) === 2) {
        //starttijden, eerst startijd en duration, dan voorraad ophalen
        this.currentItem.data.s.timeselection = []

        const extraDays = parseInt(this.currentItem.data.s.ExtraDays)
        const blockDuration = parseInt(this.currentItem.data.s.DurationMinutes);
        let lastStart = new this.$moment(this.currentItem.data.s.DurationLastStart, 'HH:mm:ss')
        const firstStart = new this.$moment(this.currentItem.data.s.BlockStart, 'HH:mm:ss')

        let currentTime = new this.$moment(firstStart)
        while (currentTime.isSameOrBefore(lastStart)) {
          let end = new this.$moment(currentTime).add(blockDuration, 'minutes')
          if (blockDuration === 0 || extraDays === -1) {
            end = new this.$moment(this.currentItem.data.s.BlockEnd, 'HH:mm:ss')
          }
          this.currentItem.data.s.timeselection.push({
            'BlockStart': currentTime.format('HH:mm:ss'),
            'BlockEnd': end.format('HH:mm:ss')
          })
          currentTime.add(15, 'minutes')
        }
        this.currentItem.max = 0
      } else if (parseInt(this.currentItem.data.a.starttijden) === 1) {
        if (this.currentItem.data.s.Free === -1) {
          this.currentItem.max = Number.MAX_SAFE_INTEGER
        } else if (this.currentItem.data.s.Free < 1) {
          this.currentItem.max = 0
        } else {
          if (this.currentItem.data.a.maxpax < this.currentItem.data.s.Free) {
            this.currentItem.max = this.currentItem.data.a.maxpax
          } else {
            this.currentItem.max = this.currentItem.data.s.Free
          }
        }

        this.currentItem.starttime = this.currentItem.data.s.BlockStart
        this.currentItem.endtime = this.currentItem.data.s.BlockEnd
      } else {
        this.currentItem.max = Number.MAX_SAFE_INTEGER
        this.currentItem.starttime = null
        this.currentItem.endtime = null
        this.currentItem.slot = 0
      }
      if(!this.currentItem.tarif){
        await this.getTarif(this.currentItem.slot)
      }
      if(this.isFastCashier){
            this.currentItem.pax = 1;
            this.currentItem.tarif["0"].pax = 1;
            this.addToCart()
        }
    },
    setCurrentStarttime() {
      if (
          !this.currentItem.data ||
          !this.currentItem.data.a ||
          !this.currentItem.data.a.starttijden ||
          this.currentItem.data.a.starttijden !== "2"
      ) {
        return
      }

      const chosenTimeSlot = this.currentItem.data.s.timeselection.filter(item => item.BlockStart === this.currentItem.starttime)[0]
      this.currentItem.endtime = chosenTimeSlot.BlockEnd
      this.getStockDetails(chosenTimeSlot.BlockStart.substring(0, 5))
    },

    filtering() {
      const filterValue = this.filterValue.toLowerCase()
      if (filterValue === '') {
        this.displayAssortmentList = this.assortimentTabs[this.getCurrentTabName()]
        return
      }
      this.displayAssortmentList = this.assortimentTabs[this.getCurrentTabName()].filter(item => item.weergavenaam.toLowerCase().includes(filterValue))
    },
    filterOnType(type) {
      if (type === 'isAffiliate') {
        return this.assortmentList.filter(item => item['isAffiliate'] === true)
      } else if (type === 1) {
        return this.assortmentList.filter(item => item['FKid_soort'] !== "5" && item['isAffiliate'] === false)
      } else {
        return this.assortmentList.filter(item => item['FKid_soort'] === type.toString())
      }
    },
    removeSelection(item) {
      if (item === undefined) item = "date"

      switch (item) {
        case "date":
          this.currentItem.date = null
          // eslint-disable-next-line no-fallthrough
        case "assortment":
          this.currentItem.assortment = null
          this.currentItem.data.a = null
          this.slots = []
          // eslint-disable-next-line no-fallthrough
        case "slot":
          this.tarifs = []
          this.currentItem.slot = null
          this.currentItem.data.s = null
          // eslint-disable-next-line no-fallthrough
        case "starttime":
          this.currentItem.starttime = ""
          // eslint-disable-next-line no-fallthrough
        case "tarif":
          this.currentItem.tarif = null
          this.currentItem.data.t = null
          // eslint-disable-next-line no-fallthrough
        case "pax":
          this.currentItem.pax = 0
          break;
      }

    },
    resetForm() {
      this.orderlines = []
      this.id_reservering = ''
      this.filterValue = ''
      this.currentItem = {
        data: {
          a: null,
          s: null,
          t: null,
        },
        assortment: null,
        date: null,
        max: null,
        pax: 0,
        starttime: "",
        slot: null,
        tarif: null,
      }
      if (this.$props.propdate) {
        this.currentItem.date = this.$props.propdate
      }
    },
    chgPax(direction, tarif) {
      const aanbodminpax = this.currentItem.data.a.minpax
      const aanbodmaxpax = this.currentItem.data.a.maxpax

      if (direction === "add") {
        this.currentItem.pax += 1;
        let currentTarifPax = this.currentItem.tarif[tarif].pax

        if (this.currentItem.pax > this.currentItem.max) {
          this.currentItem.pax = this.currentItem.max
          return
        }
        if (this.currentItem.pax > aanbodmaxpax) {
          this.currentItem.pax = aanbodmaxpax
          return
        }
        currentTarifPax += 1;
        this.currentItem.tarif[tarif].pax = currentTarifPax
      }

      if (direction === "sub") {
        let currentTarifPax = this.currentItem.tarif[tarif].pax

        if ((this.currentItem.pax - 1) < aanbodminpax) {
          return
        }

        currentTarifPax -= 1;
        if (currentTarifPax < 0) {
          this.currentItem.tarif[tarif].pax = 0
          return
        }
        this.currentItem.pax -= 1;
        this.currentItem.tarif[tarif].pax = currentTarifPax
      }

      this.updateSubTotal()
    },
    updateSubTotal() {
      let subtotal = 0

      Object.entries(this.currentItem.data.t).forEach(([key, value]) => {
        subtotal += this.currentItem.tarif[value.id_tarif].pax * value.price
        key
      });

      this.subtotal = subtotal
    },
    getCurrentTabName() {
      let i = 0;
      let currentTabName = null
      for (const t in this.assortimentTabs) {
        if (i === this.currentTab) {
          currentTabName = t
          break
        }
        i++;
      }
      return currentTabName
    },
    addToCart() {
      const payload = []

      for (const tarif in this.tarifs) {
        if (this.currentItem.tarif[this.tarifs[tarif].id_tarif].pax === 0) {
          continue
        }

        if (this.currentItem.slot === undefined) {
          this.currentItem.slot = 0
        }
        const slotData = this.currentItem.data.s ? JSON.parse(JSON.stringify(this.currentItem.data.s)) : {}
        const tarifData = JSON.parse(JSON.stringify(this.tarifs[tarif]))

        payload.push({
          id: 0,
          id_assortiment: this.currentItem.assortment,
          id_block: this.currentItem.slot,
          id_tarif: this.tarifs[tarif].id_tarif,
          date: this.currentItem.date,
          starttime: this.currentItem.starttime ? this.currentItem.starttime : '00:00',
          endtime: this.currentItem.endtime ? this.currentItem.endtime : '00:00',
          pax: this.currentItem.tarif[this.tarifs[tarif].id_tarif].pax,
          data: {
            a: JSON.parse(JSON.stringify(this.currentItem.data.a)),
            s: slotData,
            t: tarifData
          }
        })
      }

      this.$emit('newRowsAdded', payload)
      if(!this.isFastCashier){
        setTimeout(() => {
          this.removeSelection()
        }, 500)
      }
    },
    toggleFetchAvailablity() {
      if (this.fetchAvailablity) {
        this.getAvailability()
      }
    },
    async getAvailability() {
      if (!this.currentItem.date) {
        return
      }
      if (!this.fetchAvailablity) {
        return
      }
      if (!this.assortimentTabs[this.getCurrentTabName()]) {
        return
      }
      this.isLoading = true
      const date = this.currentItem.date
      const objAssortimentIds = this.assortimentTabs[this.getCurrentTabName()].map((item) => item.id_assortiment)

      try {
        const result = await AssortimentService.getStockDataByArrayAssortimentDate(objAssortimentIds, date)
        for (const id_assortiment in result) {
          if (!this.assortmentListAvailability[id_assortiment]) {
            this.$set(this.assortmentListAvailability, id_assortiment, {})
          }
          if (!this.assortmentListAvailability[id_assortiment][date]) {
            this.$set(this.assortmentListAvailability[id_assortiment], date, {})
          }
          this.$set(this.assortmentListAvailability[id_assortiment][date], 'status', result[id_assortiment].status)
          this.$set(this.assortmentListAvailability[id_assortiment][date], 'perc', result[id_assortiment].perc)
          this.$set(this.assortmentListAvailability[id_assortiment][date], 'data', result[id_assortiment].data)
        }
      } catch (e) {
        this.toast('err', e.toString())
      }
      this.isLoading = false
    },
    availability(id_assortiment) {
      if (this.assortmentListAvailability[id_assortiment] && this.assortmentListAvailability[id_assortiment][this.currentItem.date]) {
        const status = this.assortmentListAvailability[id_assortiment][this.currentItem.date].status
        let perc = this.assortmentListAvailability[id_assortiment][this.currentItem.date].perc
        let statusClass = "statusZ"
        let desc = ""
        let disabled = false
        let striped = true
        if (status) {
          statusClass = `status${status}`
        }
        if (status === "C") {
          perc = 100
          disabled = true
          striped = true
          desc = this.$t('alg.winkelgesloten')
        }
        if (status === "N") {
          perc = 100
          disabled = true
          striped = true
          if (this.assortmentListAvailability[id_assortiment][this.currentItem.date].data.data.StockDetails) {
            switch (this.assortmentListAvailability[id_assortiment][this.currentItem.date].data.data.StockDetails) {
              case 'Booking margin applied!':
                desc = this.$t('alg.boekingsmarge')
                break;
              case 'Assortiment Disabled!':
                desc = this.$t('alg.aanboduitgeschakeld')
                break;
              default:
                desc = this.assortmentListAvailability[id_assortiment][this.currentItem.date].data.data.StockDetails
                break;
            }
          } else {
            desc = this.$t('alg.onbekend')
          }
        }
        return {status, statusClass, perc, desc, disabled, striped}
      }
      return {status: "Z", statusClass: 'statusZ', perc: 100, desc: '', disabled: false, striped: true}
    },
    availabilitySlot(slot) {
      const status = slot.StockStatus
      let perc = slot.StockDetails
      let statusClass = "statusZ"
      let desc = ""
      let disabled = false
      let striped = true
      if (status) {
        statusClass = `status${status}`
      }
      if (status === "C") {
        perc = 100
        disabled = true
        striped = true
        desc = this.$t('alg.winkeldicht')
      } else if (status === "N") {
        perc = 100
        disabled = true
        striped = true
        if (slot.StockDetails) {
          switch (slot.StockDetails) {
            case 'Booking margin applied!':
              desc = this.$t('alg.boekingsmarge')
              break;
            case 'Assortiment Disabled!':
              desc = this.$t('alg.aanboduitgeschakeld')
              break;
            case 'BLOCK DISABLED':
              desc = this.$t('alg.blokuitgeschakeld')
              break;
            default:
              desc = slot.StockDetails
              break;
          }
        } else {
          desc = this.$t('alg.onbekend')
        }
      } else {
        desc = (slot.Free > 99999 || slot.Free === -1 ? '&#8734;' : slot.Free)
      }
      return {status, statusClass, perc, desc, disabled, striped}
    },
    fillTabs() {
      return new Promise((resolve) => {
        this.getAssortiment().then((tabdata) => {
          for (const tab in tabdata) {
            this.$set(this.assortimentTabs, tab, tabdata[tab])
          }
          this.displayAssortmentList = this.assortimentTabs[this.getCurrentTabName()]
          this.getAvailability()
          resolve()
        })
      })
    },
    async init() {
      if(!this.currentShop){
        this.isLoading = true
        setTimeout(this.init,200)
      }else{
        this.isInitalizing = true
        this.isLoading = true
        if (this.isFastCashier) {
          this.fetchAvailablity = false
        }
        await this.fillTabs()
        this.isLoading = false
        this.isInitalizing = false
      }

    }
  }
  ,
  watch: {
    propdate: function () {
      this.currentItem.date = this.$props.propdate
    }
    ,
    'currentItem.date':
        function (newval) {
          if (newval !== undefined) {
            this.getAvailability()
          }
        },
    currentTab:
        function () {
          this.filterValue = ''
          this.displayAssortmentList = this.assortimentTabs[this.getCurrentTabName()]
          this.getAvailability()
        },
    'currentItem.starttime':
        function () {
          this.setCurrentStarttime()
        },
  }
  ,
  filters: {}
  ,
  created() {
    this.$parent.$on('clearAddReservationSelection',
        () => {
          this.resetForm()
          this.fillTabs()
        })
    if (this.$props.propdate) {
      this.currentItem.date = this.$props.propdate
    }
  }
  ,
  beforeMount() {
    this.init()
  }
  ,
  mounted() {
    this.displayAssortmentList = this.assortimentTabs[this.getCurrentTabName()]
  }
  ,
  beforeUpdate() {
  }
  ,
  beforeDestroy() {
  }
  ,
}
</script>

<style scoped lang="scss">
.tiles {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
}

.tile {
  height: 100px;
  display: grid;
  user-select: none;

  &.assortiment {
    grid-template-rows: (20px auto 20px);
    background-color: $primary;
    color: $white;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover:not(.disabled) {
      background-color: #1d7c89;
      border-color: #1b737f;
    }

    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }
  }

  &.slots {
    grid-template-rows: (20px auto);
    background-color: $primary;
    color: $white;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover:not(.disabled) {
      background-color: #1d7c89;
      border-color: #1b737f;
    }

    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }
  }

}


.progress {
  background-color: transparent;
}

.statusH {
  background-color: $lk_green;
}

.statusM {
  background-color: $lk_yellow_darker;
}

.statusL {
  background-color: $lk_orange;
}

.statusC {
  background-color: $lk_red;
}

.statusN {
  background-color: $lk_red;
}

.statusZ {
  background-color: $lk_blue;
}

@include media-breakpoint-up(md) {
  .tile {
    height: $tile-height-lg;
  }
}

</style>
