<template>
  <div id="customer">
    <b-card header-tag="header" class="mb-2 border-0 shadow-sm" no-body>
      <template #header>
        <div class="d-flex justify-content-between">
          <div>
            <span class="spantoggle" v-b-toggle:collapsecustomer>
                <b-icon icon="chevron-up" class="when-open"></b-icon>
                <b-icon icon="chevron-down" class="when-closed"></b-icon>
                <span>
                  {{ $t('klant.klantdetails') }}
                </span>
              </span>
          </div>
          <div v-if="lastnameInHeader && customerData && customerData.lastname ">
            <h5 class="font-weight-bold">{{customerData.lastname.value}}</h5>
          </div>
          <b-button ref="addButton" v-if="$v.$anyDirty" v-on:click="saveCustomer()" size="sm">
            {{ $t('alg.opslaan') }}
          </b-button>
        </div>
      </template>

      <!-- klantdata met chid -->

      <b-collapse id="collapsecustomer"
                  v-if="customerData && customerData.customerhid && customerData.customerhid.value !== 'a64eba55b6bbd07369daf8ae62731a53dcd042d1d023f1e65f07e080a0efac90'"
      >
        <b-card-body :class="{'data-editable': hasRights}">
          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group>
                <template #label >
                  <div>{{ $t('klant.aanhef') }}</div>
                </template>
                <b-form-radio-group
                    id="c_title"
                    v-model="$v.customerData.title.value.$model"
                    name="c_title"
                    button-variant="outline-primary"
                    buttons
                    :disabled="!hasRights"
                >
                  <b-form-radio value="Dhr.">{{ $t('alg.aanhefman') }}</b-form-radio>
                  <b-form-radio value="Mevr.">{{ $t('alg.aanhefvrouw') }}</b-form-radio>
                  <b-form-radio value="Anders">{{ $t('alg.aanhefanders') }}</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" class="text-sm-right">
              <b-form-group>
                <template #label >
                    <div>{{ $t('klant.emailmarketing') }}</div>
                </template>

                <b-form-radio-group
                    id="c_directmail"
                    name="c_directmail"
                    v-model="$v.customerData.permissionEM.value.$model"
                    button-variant="outline-primary"
                    buttons
                    :disabled="!hasRights"
                >
                  <b-form-radio :value="true">{{ $t('alg.ja') }}</b-form-radio>
                  <b-form-radio :value="false">{{ $t('alg.nee') }}</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" xl="4">
              <b-form-group>
                <template #label >
                  <div>{{ $t('klant.voorletters') }}</div>
                </template>

                <b-form-input
                    v-if="customerData.firstname.edit"
                    v-model="$v.customerData.firstname.value.$model"
                    @blur="customerData.firstname.edit = false; $emit('update')"
                    @keyup.enter="customerData.firstname.edit=false; $emit('update')"
                    v-focus
                    id="c_firstname" name="c_firstname"></b-form-input>
                <div v-else>
                  <label class="cust_label text-truncate" @click="customerData.firstname.edit = hasRights;">
                    {{ customerData.firstname.value }} </label>
                </div>
              </b-form-group>

              <b-form-group
                  :state="validateState('lastname')"
              >
                <template #label >
                  <div class="d-flex justify-content-between">
                    <div>{{ $t('klant.achternaam') }}</div>
                    <div>
                      <b-form-invalid-feedback
                          :state="validateState('lastname')"
                      >
                        {{ $t('validationmessages.minlength', ['3']) }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </template>

                <b-form-input
                    v-if="customerData.lastname.edit"
                    v-model="$v.customerData.lastname.value.$model"
                    @blur="customerData.lastname.edit = false; $emit('update')"
                    @keyup.enter="customerData.lastname.edit=false; $emit('update')"
                    :state="validateState('lastname')"
                    v-focus
                    id="c_lastname" name="c_lastname"></b-form-input>
                <div v-else>
                  <label
                      class="cust_label text-truncate"
                      :class="validateState('lastname') ? 'is-invalid' : ''"
                      @click="customerData.lastname.edit = hasRights;"
                  >
                    {{ customerData.lastname.value }} </label>
                </div>
              </b-form-group>

              <b-form-group
                    :disabled="!hasRights">
                <template #label >
                  <div>{{ $t('klant.bedrijfsnaam') }}</div>
                </template>

                <b-form-input
                    v-if="customerData.companyname.edit"
                    v-model="$v.customerData.companyname.value.$model"
                    @blur="customerData.companyname.edit = false; $emit('update')"
                    @keyup.enter="customerData.companyname.edit=false; $emit('update')"
                    v-focus
                    id="c_companyname" name="c_companyname"></b-form-input>
                <div v-else>
                  <label class="cust_label text-truncate" @click="customerData.companyname.edit = hasRights;">
                    {{ customerData.companyname.value }} </label>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" xl="4">
              <b-form-group>
                <template #label >
                  <div>{{ $t('klant.adres') }}</div>
                </template>

                <b-form-input
                    v-if="customerData.address.edit"
                    v-model="$v.customerData.address.value.$model"
                    @blur="customerData.address.edit = false; $emit('update')"
                    @keyup.enter="customerData.address.edit=false; $emit('update')"
                    v-focus
                    id="c_adres" name="c_adres"></b-form-input>
                <div v-else>
                  <label class="cust_label text-truncate" @click="customerData.address.edit = hasRights;">
                    {{ customerData.address.value }} </label>
                </div>
              </b-form-group>

              <b-form-group>
                <template #label >
                  <div>{{ $t('klant.postcode') }}</div>
                </template>

                <b-form-input
                    v-if="customerData.postcode.edit"
                    v-model="$v.customerData.postcode.value.$model"
                    @blur="customerData.postcode.edit = false; $emit('update')"
                    @keyup.enter="customerData.postcode.edit=false; $emit('update')"
                    v-focus
                    id="c_postcode" name="c_postcode"></b-form-input>
                <div v-else>
                  <label class="cust_label text-truncate" @click="customerData.postcode.edit = hasRights;">
                    {{ customerData.postcode.value }} </label>
                </div>
              </b-form-group>

              <b-form-group>
                <template #label >
                  <div>{{ $t('klant.plaats') }}</div>
                </template>

                <b-form-input
                    v-if="customerData.city.edit"
                    v-model="$v.customerData.city.value.$model"
                    @blur="customerData.city.edit = false; $emit('update')"
                    @keyup.enter="customerData.city.edit=false; $emit('update')"
                    v-focus
                    id="c_city" name="c_city"></b-form-input>
                <div v-else>
                  <label class="cust_label text-truncate" @click="customerData.city.edit = hasRights;">
                    {{ customerData.city.value }} </label>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" xl="4">
              <b-form-group>
                <template #label >
                  <div>{{ $t('klant.telefoonnummer') }}</div>
                </template>

                <b-form-input
                    v-if="customerData.mobilephone.edit"
                    v-model="$v.customerData.mobilephone.value.$model"
                    @blur="customerData.mobilephone.edit = false; $emit('update')"
                    @keyup.enter="customerData.mobilephone.edit=false; $emit('update')"
                    v-focus
                    id="c_mobilephone" name="c_mobilephone"></b-form-input>
                <div v-else>
                  <label
                      class="cust_label text-truncate"
                      @click="customerData.mobilephone.edit = hasRights;"
                  >
                    {{ customerData.mobilephone.value }} </label>
                </div>
              </b-form-group>

              <b-form-group
                  :state="validateState('email')"
              >
                <template #label>
                  <div class="d-flex justify-content-between">
                    <div>{{ $t('klant.emailadres') }}</div>
                    <div>
                      <b-form-invalid-feedback
                          :state="validateState('email')"
                      >
                        {{ $t('validationmessages.invalidEmail') }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </template>

                <b-form-input
                    v-if="customerData.email.edit"
                    v-model="$v.customerData.email.value.$model"
                    @blur="customerData.email.edit = false; $emit('update')"
                    @keyup.enter="customerData.email.edit=false; $emit('update')"
                    :state="validateState('email')"
                    v-focus
                    id="c_email" name="c_email">
                </b-form-input>
                <div v-else>
                  <label
                      class="cust_label text-truncate"
                      :class="validateState('email') ? 'is-invalid' : ''"
                      @click="customerData.email.edit = hasRights;">
                    {{ customerData.email.value }} </label>
                </div>
              </b-form-group>

              <b-form-group>
                <template #label >
                  <div>{{ $t('klant.taal') }}</div>
                </template>

                <b-form-select
                    id="c_language"
                    name="c_language"
                    v-if="customerData.language.edit"
                    v-model="$v.customerData.language.value.$model"
                    :options="languages"
                    @blur="customerData.language.edit = false; $emit('update')"
                    v-focus
                ></b-form-select>
                <div v-else>
                  <label class="cust_label text-truncate" @click="customerData.language.edit = hasRights;">
                    {{ customerData.language.value }} </label>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="customerData.extra1">
            <b-col cols="12" xl="4">
              <b-form-group>
                <template #label >
                  <div>{{ customerData.extra1.label | capitalize }}</div>
                </template>

                <b-form-input
                    v-if="customerData.extra1.edit"
                    v-model="$v.customerData.extra1.value.$model"
                    @blur="customerData.extra1.edit = false; $emit('update')"
                    @keyup.enter="customerData.extra1.edit=false; $emit('update')"
                    v-focus
                    id="c_extra1" name="c_extra1">
                </b-form-input>
                <div v-else>
                  <label class="cust_label text-truncate" @click="customerData.extra1.edit = hasRights;">
                    {{ customerData.extra1.value }} </label>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" xl="4">
              <b-form-group v-if="customerData.extra2">
                <template #label >
                  <div>{{ customerData.extra2.label | capitalize }}</div>
                </template>

                <b-form-input
                    v-if="customerData.extra2.edit"
                    v-model="$v.customerData.extra2.value.$model"
                    @blur="customerData.extra2.edit = false; $emit('update')"
                    @keyup.enter="customerData.extra2.edit=false; $emit('update')"
                    v-focus
                    id="c_extra2" name="c_extra2"
                >
                </b-form-input>
                <div v-else>
                  <label class="cust_label text-truncate" @click="customerData.extra2.edit = hasRights;">
                    {{ customerData.extra2.value }} </label>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" xl="4">
              <b-form-group v-if="customerData.extra3">
                <template #label >
                  <div>{{ customerData.extra3.label | capitalize }}</div>
                </template>

                <b-form-input
                    v-if="customerData.extra3.edit"
                    v-model="$v.customerData.extra3.value.$model"
                    @blur="customerData.extra3.edit = false; $emit('update')"
                    @keyup.enter="customerData.extra3.edit=false; $emit('update')"
                    v-focus
                    id="c_extra3" name="c_extra3">
                </b-form-input>
                <div v-else>
                  <label class="cust_label text-truncate" @click="customerData.extra3.edit = hasRights;">
                    {{ customerData.extra3.value }} </label>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import CustomerService from '../services/customer.service';
import {validationMixin} from 'vuelidate'
// eslint-disable-next-line no-unused-vars
import {required, minLength, minValue, maxValue, requiredIf, email, numeric} from "vuelidate/lib/validators";

export default {
  props: ['chid', 'lastname', 'resid', 'lastnameInHeader'],
  name: 'Customer',
  mixins: [validationMixin],
  validations: {
    customerData: {
      address: {
        value: {}
      },
      city: {
        value: {}
      },
      companyname: {
        value: {}
      },
      email: {
        value: {
          email
        }
      },
      extra1: {
        value: {}
      },
      extra2: {
        value: {}
      },
      extra3: {
        value: {}
      },
      firstname: {
        value: {}
      },
      language: {
        value: {}
      },
      lastname: {
        value: {
          required,
          minLength: minLength(3)
        }
      },
      mobilephone: {
        value: {}
      },
      permissionEM: {
        value: {}
      },
      postcode: {
        value: {}
      },
      title: {
        value: {}
      }
    }
  },
  data() {
    return {
      customerData: null,
      languages: [
        {value: 'NL', text: 'Nederlands'},
        {value: 'EN', text: 'English'},
        {value: 'DE', text: 'Deutsch'},
        {value: 'FR', text: 'Français'},
        {value: 'ES', text: 'Español'},
        {value: 'PT', text: 'Portuguese'},
      ],
    };
  },
  computed: {
    currentShop() {
      return this.$store.getters["bus/currentShop"]
    },
    hasRights() {
      return this.$store.getters["user/hasRight"]("customer_crud");
    }
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.customerData[name].value;
      return $dirty ? !$error : null;
    },
    getCustomer() {
      if(this.chid === 0 || this.chid === null) {
        return
      }
      CustomerService.getCustomer(this.currentShop.hashcode, this.chid)
          .then(
              data => {
                let cust_data = {}
                for (const detail_key in data) {
                  if (detail_key === "extra1") {
                    let i = 1
                    for (const extra of data.extra1) {
                        let extra_data = {
                          'name': extra.id,
                          'label': extra.label,
                          'value': extra.value,
                          'edit': false
                        }
                        let item = "extra" + i
                        cust_data[item] = extra_data
                      i++
                    }
                  } else {
                    let detail_data = {
                      'name': detail_key,
                      'value': data[detail_key],
                      'edit': false
                    }
                    cust_data[detail_key] = detail_data
                  }
                }
                this.customerData = cust_data
              }
          )
          .catch(
              err => {
                this.customerData = null
                this.toast('err', err)
              })
    },
    initCustomer(chid) {
      if (chid !== null && chid !== 0) {
        this.getCustomer()
        this.$emit('customerSelected', chid)
        return
      }
      this.customerData = {}
      this.customerData.lastname = {
          name: "lastname",
          value: "",
          edit: true,
        }
      this.customerData.isnew = true
    },
    async saveCustomer() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.toast('notice',this.$t('klant.detailsnietopgeslagen'), this.$t('alg.fout'))
      } else {
        try {
          // console.log(this.$v.customerData.$model)
          const data = await CustomerService.setCustomer(this.currentShop.hashcode, this.$v.customerData.$model)
          this.toast('success', this.$t('klant.detailsopgeslagen'), this.$t('alg.opgeslagen'))
          this.initCustomer(data.customerhid)
          this.$v.$reset()
        } catch (err) {
          this.toast('err', err)
        }
      }
    }
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus()
      }
    }
  },
  watch: {
    chid:  function () {
      this.initCustomer(this.chid)
    }
  },
  created() {
    if(this.lastname !== "" && (this.chid === null || this.chid === 0)){
      this.customerData = {}
      this.customerData.lastname = {
        name: "lastname",
        value: this.lastname,
        edit: true,
      }
      this.customerData.isnew = true
      this.saveCustomer()
      return
    }else{
      this.initCustomer(this.chid)
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
}
</script>

<style lang="scss" scoped>
.is-invalid .cust_label{
  border-color: $danger;
}
.cust_label {
  box-sizing: border-box;
  width: 100%;
  height: 38px;
  margin-bottom: -6px;
  padding: 7px 13px;
  border-radius: 3px;
  border: 1px solid #bbbbbb;
  background: #ddd;  
}

.data-editable .cust_label {
  background: transparent;

  &:hover{
    background-color: #2499a91A;
    cursor: pointer;
  }
}

.spantoggle {
  &:focus {
    outline: none;
  }
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
