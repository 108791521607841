<template>
  <component :is="layout">
    <router-view :layout.sync="layout"/>
  </component>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      layout: `div`,
      lockInterval: null,
    };
  },
  methods: {
    updateLastActionDT() {
      if (
        Date.now() - 1000 > this.$store.state.misc.lastInteractionDT &&
        !this.$route.meta.noLock
      ) {
        this.$store.commit("misc/updateLastInteractionDT");
      }
    },
  },
  created() {
    if (
      this.$store.getters["bus/currentShop"] === null &&
      this.$route.path !== "/login"
    ) {
      this.$store.dispatch("bus/setDefaultShop");
    }
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)")
    if (prefersDarkScheme.matches) {
      //default is light
      this.$store.commit("misc/toggleDarkMode");
    }
  },
  mounted() {
    this.lockInterval = setInterval(() => {
      if (
        Date.now() - this.$store.getters["misc/getLockTimeout"] >
          this.$store.state.misc.lastInteractionDT &&
        !this.$route.meta.noLock
      ) {
        if (!this.$store.state.user.locked) {
          this.$store.dispatch("user/lock");
        }
      }
    }, 5000);

    // Add
    document.addEventListener("keydown", this.updateLastActionDT);
    document.addEventListener("mousemove", this.updateLastActionDT);

//addon messageing
    window.addEventListener("message", (event) => {
      if (event.origin !== "https://app.leisureking.eu") {
        return;
      } else {
        const data = JSON.parse((event.data));
        switch (data.action) {
          case 'navigate':
            console.log('cant navigate a vue app!');
            break;
          case 'meldingPop':
            // eslint-disable-next-line no-case-declarations
            let type = 'err';
            if(parseInt(data.type) === 1){
              type = 'success'
            }
            if(parseInt(data.type) === 2){
              type = 'err'
            }
            if(parseInt(data.type) === 3){
              type = 'notice'
            }
            // eslint-disable-next-line no-case-declarations
            let title = 'Addon'
            if(data.source){
              title = data.source
            }
            this.toast(type, data.message, title);
            break;
          case 'popopen':
            console.log('cant popup a vue app!');
            break;
          case 'interaction':
            this.updateLastActionDT();
            break;
          default:
            throw new Error('Unsupported action!');
        }
      }
    }, false);
  },
  destroyed() {
    clearInterval(this.lockInterval);
  },
};
</script>

<style lang="scss"></style>
